import '../bootstrap';

import { createApp } from "vue"
import Admin from './Admin.vue'
import router from './router'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from "@fortawesome/free-solid-svg-icons";
library.add(fas)
// FontAwesome end


const app = createApp({})
app.component('admin-page', Admin)
.use(router)
.mount('#admin')