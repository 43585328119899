<template>
    <div v-if="logged" class="columns is-mobile">
        <navigation-panel></navigation-panel>
        <div class="position-relative column is-mobile p-6">
            <RouterView />
        </div>
    </div>
    <div class="login-wraper">
        <div v-if="!logged" class="login-page">
            <form class="form" @keydown.prevent.enter="runLogin">
                <img src="/img/bielykruhslogom.webp" alt="Tesa-security logo" />
                <input
                    placeholder="email"
                    tabindex="0"
                    @focusin="outline1 = true"
                    @focusout="outline1 = false"
                    v-model="login"
                    type="text"
                    :class="{ selected: outline1 }"
                />
                <input
                    placeholder="heslo"
                    tabindex="0"
                    @focusin="outline2 = true"
                    @focusout="outline2 = false"
                    v-model="pass"
                    type="password"
                    :class="{ selected: outline2 }"
                />
                <button @click.prevent="runLogin">Prihlásiť</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import NavigationPanel from './componnets/elements/navigation/NavigationPanel.vue'
export default {
    components: {
        NavigationPanel,
    },

    props: {
        logged: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            login: '',
            pass: '',
            outline1: false,
            outline2: false,
        }
    },

    methods: {
        runLogin() {
            if (this.login.length < 4 || this.pass.length < 5) return
            axios
                .get('/sanctum/csrf-cookie')
                .then(() => {
                    axios.post('/login', {
                        email: this.login,
                        password: this.pass,
                    })
                })
                .then(() => {
                    const url = window.location.origin
                    setTimeout(() => {
                        window.location.href = url + '/admin/dash'
                    }, 500)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../sass/main/variables';

.selected {
    outline: 2px solid $master-color !important;
}

.position-relative {
    position: relative;
}

.login-wraper {
    width: 100wh;
    height: 100vh;
    background-color: lighten($master-color-noalpha, 40%);
}

.login-page {
    position: absolute;
    width: 18em;
    height: 12em;

    top: 50%;
    left: 50%;

    margin-top: -15em;
    margin-left: -9em;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    border-radius: 0.4em;
    background-color: lighten($master-color-noalpha, 20%);
    padding: 2em;
    box-shadow: 0 0 50px lighten($master-color-noalpha, 15%);

    img {
        filter: drop-shadow(0px 0px 10px $master-color);
    }

    input {
        outline: none;
        border: none;
        padding: 0.2em 0.5em 0.2em 0.5em;
        font-size: 1em;
        border-radius: 0.2em;
        transition: all 150ms ease-in-out;
    }
}

button {
    margin: 0 auto;
    margin-top: 1em;
    width: 6em;
    height: 2em;
    outline: none;
    border: none;
    border-radius: 0.3em;
    color: $master-color;
    font-weight: bold;
    background-color: lighten($master-color-noalpha, 50%);
    border: 1px solid $master-color;
    font-size: 1.2em;
    transition: all 150ms ease-in-out;
    cursor: pointer;

    &:hover {
        border: 1px solid $master-color;
        border-radius: 0.3em;
        box-shadow: 0 0 15px $master-color;
        color: $master-color;
    }
}
</style>
