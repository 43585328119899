<template>
    <Transition name="slide-fade">
        <div v-if="message.show" class="notification" :class="message.class">
            <button @click="message.hide" class="delete is-small"></button>
            <p>{{ message.text }}</p>
        </div>
    </Transition>
</template>

<script>
import { message } from '../../store'

export default {
    data() {
        return {
            message,
        }
    },
}
</script>

<style lang="scss" scoped>
.notification {
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    margin: 3em;
    box-shadow: 0 0 4px lightgray;

    p {
        font-weight: 700;
    }
}

.slide-fade-enter-active {
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.slide-fade-leave-active {
    transition: all 0.2s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(150px);
    opacity: 0;
}
</style>
