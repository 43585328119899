import { reactive } from 'vue'


// FlashComponent store
export const message = reactive({
  show: false,
  text: 'default',
  class: 'is-success',

  visible(text, color) {
    this.class = color
    this.text = text
    this.show = true
    setTimeout(() => {
        this.show = false
    }, 2500);
  },
  
  hide() {
    this.show = false
  }  
})

// Seo Panel store
export const seo = reactive({
  data: {
    id: '',
    user_id: '',
    title: '',
    description: '',
    page: '',
    created_at: '',
    updated_at: '',
    text: '',
  },
  
  setObject(data) {
    this.data = data
  },
  
  setText(text) {
    this.data.text = text
  },
  
  set(text, target) {
    if (target === 'Title') this.data.title = text
    if (target === 'Description') this.data.description = text
  },
  
  get(target) {
    if (target === 'Title') return this.data.title
    if (target === 'Description') return this.data.description
  },
  
  setUserId(text) {
    this.data.user_id = text
  }
})
