import DashboardPanel from './componnets/views/DashboardPanel'
import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/admin',
            redirect: '/admin/dash',
            // You could also have named views at tho top
            children: [
                {
                    path: 'dash',
                    component: DashboardPanel,
                },
                {
                    path: 'seo',
                    redirect: '/admin/seo/master-page',
                    component: () => import('./componnets/views/SeoPanel'),
                    children: [
                        {
                            path: 'master-page',
                            component: () => import('./componnets/views/seo/MasterPage')
                        },
                        {
                            path: 'smart-home',
                            component: () => import('./componnets/views/seo/SmartHome')
                        },
                        {
                            path: 'camera-system',
                            component: () => import('./componnets/views/seo/CameraSystem')
                        },
                        {
                            path: 'security-system',
                            component: () => import('./componnets/views/seo/SecuritySystem')
                        },
                        {
                            path: 'telephones',
                            component: () => import('./componnets/views/seo/Telephones')
                        },
                        {
                            path: 'reference-page',
                            component: () => import('./componnets/views/seo/ReferencePage')
                        },
                        {
                            path: 'contact-page',
                            component: () => import('./componnets/views/seo/ContactPage')
                        },
                        {
                            path: 'faq-page',
                            component: () => import('./componnets/views/seo/FaqPage')
                        },
                        {
                            path: 'conditions-page',
                            component: () => import('./componnets/views/seo/ConditionsPage')
                        },
                    ]
                },
                {
                    path: 'footer',
                    component: () => import('./componnets/views/FooterPanel')
                },
                {
                    path: 'header',
                    component: () => import('./componnets/views/HeaderPanel')
                },
                {
                    path: 'offers',
                    component: () => import('./componnets/views/OffersPanel')
                },
                {
                    path: 'services',
                    component: () => import('./componnets/views/ServicesPanel')
                },
                {
                    path: 'title',
                    component: () => import('./componnets/views/TitlePanel')
                },
            ],
        },
    ],
  })

export default router