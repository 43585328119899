<template>
    <nav class="menu box column is-2 pt-6 pl-5">
        <a href="/" target="_blank" class="to-home"><h4>Tesa Security</h4></a>
        <ul class="menu-list">
            <li>
                <router-link class="item" to="/admin/dash"
                    >Dashboard</router-link
                >
            </li>
            <li>
                <router-link class="item" to="/admin/seo">SEO</router-link>
            </li>
        </ul>
        <p class="element-list menu-label has-text-black">Hlavná stránka</p>
        <ul class="menu-list">
            <li>
                <router-link class="item" to="/admin/header"
                    >Hlavička</router-link
                >
            </li>
            <li>
                <router-link class="item" to="/admin/title"
                    >Hlavný nadpis</router-link
                >
            </li>
            <li>
                <router-link class="item" to="/admin/services"
                    >Služby</router-link
                >
            </li>
            <li>
                <router-link class="item" to="/admin/offers"
                    >Ponuka</router-link
                >
            </li>
            <li>
                <router-link class="item" to="/admin/footer"
                    >Pätička</router-link
                >
            </li>
        </ul>
        <div class="this-user">
            <p>{{ this.user }}</p>
            <button
                @click="logout"
                class="button is-warning is-hovered is-small"
            >
                Odhlásiť
            </button>
        </div>
    </nav>

    <FlashMessage />
</template>

<script>
import axios from 'axios'
import FlashMessage from '../FlashMessage.vue'
export default {
    components: {
        FlashMessage,
    },

    methods: {
        logout() {
            axios.post('/logout').then(() => (location.href = '/login'))
        },
    },

    data() {
        return {
            user: '',
        }
    },

    created() {
        axios.get('/api/user').then((response) => {
            this.user = response.data.name
        })
    },
}
</script>

<style lang="scss" scoped>
.router-link-active {
    background-color: rgba(25, 151, 229, 0.2);
}

.item {
    transition: all 0.1s ease-in-out;
}
.item:hover {
    background-color: rgba(25, 151, 229, 0.5);
}
.menu {
    background-color: white;
    min-height: 100vh;
    margin-bottom: 0;
    min-width: 272px;

    display: flex;
    flex-direction: column;

    a {
        border-radius: 6px;
    }
}

.element-list {
    color: lighten(black, 60%) !important;
}

h4 {
    position: relative;

    font-weight: 700;
    font-size: 1.2em;
    text-transform: uppercase;

    margin-bottom: 40px;
    margin-left: 50px;
}

h4::before {
    position: absolute;
    top: 50%;
    left: -55px;
    margin-top: -25px;

    background-image: url('/img/bielykruhslogom.webp');
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content: '';
}

h4::after {
    content: 'ADMIN PANEL';
    margin-left: 3px;
    display: block;
    font-size: 0.6em;
    font-weight: 400;
}

.this-user {
    margin-top: auto;
}
</style>
